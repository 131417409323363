<!--  -->
<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="seaDetail-container"
  >
    <a-drawer
      class="companyDetail-drawer"
      :title="rowName+'详情'"
      placement="right"
      :closable="true"
      :visible="drawer"
      @close="handleClose"
    >
      <!-- 筛选 -->
      <div class="common-screen-container">
        <div class="common-input-container">
          <span>月份筛选:</span>
          <el-date-picker
            v-model="params.checkDate"
            class="common-screen-input"
            type="month"
            placeholder="选择月"
            value-format="yyyy-MM"
            @change="commonFun"
          >
          </el-date-picker>
        </div>
        <div class="common-input-container">
          <span>所属部门:</span>
          <a-cascader
            class="common-screen-input"
            v-model="params.departId"
            :options="selectDepart"
            :fieldNames="{
              label: 'name',
              value: 'id',
              children: 'child'
            }"
            placeholder="请选择"
            change-on-select
            @change="commonFun"
          />
        </div>
        <div class="common-input-container">
          <span>客服名称:</span>
          <el-input
            class="common-screen-input"
            placeholder="请输入客户名称"
            v-model="params.nickName"
            clearable
            @clear="() => clearInput()"
          ></el-input>
        </div>
        <span class="common-input-container">
          <el-button
            style="margin: 0"
            class="common-screen-btn"
            type="primary"
            @click="() => commonFun()"
            >查 询</el-button
          >
          <el-button
            class="common-screen-btn"
            plain
            @click="
              () => {
                resetBtn();
              }"
            >重 置</el-button
          >
        </span>
      </div>
      <!-- 表格 -->
        <el-table
          :data="tableData"
          class="common-table-haveborder common-table-bgGrey th"
          border
        >
          <el-table-column
            prop="nickName"
            label="客服名称"
            :formatter="tableColumn"
          ></el-table-column>
          <el-table-column
            prop="departName"
            label="所属部门"
            :formatter="tableColumn"
          ></el-table-column>
          <el-table-column
            prop="calculatePrice"
            label="扣款金额(元)"
            :formatter="tableColumn"
          ></el-table-column>
          <el-table-column
            prop="checkDate"
            label="扣款时间"
            :formatter="tableColumn"
          ></el-table-column>
        </el-table>
        <el-pagination
          style="text-align: center"
          @size-change="(e) => pageFun(e, 'pageSize')"
          @current-change="(e) => pageFun(e)"
          :page-sizes="pagination.pageSizes"
          :page-size="params.pageSize"
          :current-page="params.pageNum"
          layout="total, prev, pager, next,sizes, jumper"
          :total="pagination.total"
        >
        </el-pagination>
    </a-drawer>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { selectDeductList } from "../../../service/portrait"
import { Config, tableColumn } from "@/utils";
import { commonDepartAll } from "../../../service/common.js";
import { Cascader,Drawer } from "ant-design-vue";
import Vue from "vue";
Vue.use(Drawer);

export default {
  name: "",
  components: { aCascader:Cascader,aDrawer: Drawer },
  props: ["rowName", "drawer"],
  data() {
    return {
      tableColumn,
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      companyId: this.$sto.get(Config.constants.userInfo).company_id, // 所属公司
      loading: false,
      selectDepart: [], // 部门下拉
      tableData: []
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    if (this.companyId) {
      this.params.companyId = this.companyId;
      this.commonDepartAll(this.companyId);
    }
  },
  //方法集合
  methods: {
    async commonDepartAll(id) {
      // 部门 下拉
      let resData = (await commonDepartAll({}, id)).data;
      this.selectDepart = resData ? resData : [];
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.getDetailsData(this.rowName);
    },
    async getDetailsData(name) {
      this.loading = true;
      let params = {...this.params}
      params.wageName = name
      if(params.departId&&params.departId.length){
        params.departId = params.departId[params.departId.length - 1]
      }
      
      const { data } = await selectDeductList(params)
      this.loading = false;
      this.tableData = data.list
      this.pagination.total = data.total;
      this.pagination.pageSize = data.size;
      
    },
    handleClose() {
      this.$emit("sysHandleClose");
    },
    clearInput() {
      // 筛选框清空-输入框
      this.getDetailsData(this.rowName);
    },
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.getDetailsData(this.rowName);
    },
    resetBtn() {
      this.params = {
        pageNum: 1,
        pageSize: 10
      };
      this.getDetailsData(this.rowName);
    }
  }
};
</script>

<style lang="less" scoped>
.companyDetail-drawer {
  /deep/ .ant-cascader-picker-label {
    width: 148px !important;
  }
  /deep/.ant-drawer-content-wrapper {
    width: 1656px !important;
  }
  /deep/ .ant-drawer-body {
    padding: 0 32px !important;
  }
  .create-company-conatiner {
    table {
      border: 1px solid #afafaf;
      box-sizing: border-box;
      td {
        border: 1px solid #afafaf;
        padding: 12px 16px;
      }
    }
  }
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .fileNames {
      color: #1890ff;
      margin-top: 8px;
      word-break: break-all;
      cursor: pointer;
    }
    .drawer-left {
      padding: 32px 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
      .left-top {
        display: flex;
        justify-content: space-between;
        .demo-ruleForm {
          width: 920px;
        }
      }
      .tabsDetail {
        margin-top: 24px;
      }
      .upload-container {
        margin-top: 24px;
        .fileList-container {
          border: 1px solid #ebeef5;
          margin-top: 12px;
          border-radius: 4px;
          .fileItem {
            display: flex;
            justify-content: space-between;
            padding: 0 24px;
            border-bottom: 1px solid #ebeef5;
            height: 74px;
            line-height: 74px;
            align-items: center;
            .fileItem-btn-Container {
              display: flex;
              .fileItemBtn {
                margin-right: 16px;
                color: #1890ff;
                cursor: pointer;
              }
            }
          }
        }
        .upload-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999999;
          .tip {
            font-size: 12px;
          }
          .upload-title {
            font-size: 18px;
            color: #101010;
          }
          .uploadBtn-container {
            width: 100px;
            height: 32px;
            line-height: 32px;
            border: 1px solid #1890ff;
            color: #1890ff;
            font-size: 14px;
            background: #fff;
            text-align: center;
            border-radius: 4px;
            display: inline-block;
            margin-left: 16px;
            position: relative;
            .uploadBtn-input {
              width: 100px;
              height: 32px;
              cursor: pointer;
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
              font-size: 0;
            }
          }
        }
      }
      .cancel {
        cursor: pointer;
      }
      /deep/ .el-input__inner,
      .el-input-group__append {
        height: 32px;
        line-height: 32px;
      }
      /deep/ .el-input-group__append {
        height: 30px;
        line-height: 30px;
        padding: 0 8px;
      }
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      /deep/.el-tabs__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 16px;
      }
      /deep/.el-tab-pane {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    .detail-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 16px 12px;
      .tit-info {
        display: flex;
        align-items: center;

        .shopName-custName {
          display: inline-block;
          display: block;
          color: #333;
          font-size: 18px;
        }
        .orderState {
          padding: 2px 8px;
          border-radius: 4px;
          border: 1px solid #f98787;
          color: #f98787;
          margin-left: 16px;
        }
      }
      .hoverEdit {
        display: inline-block;
        width: 330px;
        padding: 6px 12px;
        margin-bottom: 20px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95%
            center #f2f9ff;
        }
      }
    }
    .detail-content {
      .note-input-container {
        display: inline-block;
        width: 480px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .edit-input-container {
        display: inline-block;
        width: 280px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .editInputContainer {
        width: 280px;
        white-space: nowrap;
      }
      .edit-input {
        width: 180px;
        height: 32px;
        line-height: 32px;
        vertical-align: top;
      }
      .noteHoverEdit {
        display: inline-block;
        // width: 100%;
        padding: 9px 48px 9px 12px;
        font-size: 12px;
        color: #999;
        margin-bottom: 22px;
        border-radius: 4px;
        display: flex;
        .noteName {
          white-space: nowrap;
        }
        .editColor {
          vertical-align: top;
          display: inline-block;
          color: #1890ff;
          white-space: pre-wrap;
        }
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 99%
            center #f2f9ff;
        }
      }
      .hoverEdit {
        display: inline-block;
        width: 280px;
        height: 21px;
        padding: 9px 12px;
        font-size: 12px;
        color: #999;
        margin-bottom: 22px;
        border-radius: 4px;
        .editColor {
          color: #1890ff;
        }
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95%
            center #f2f9ff;
        }
      }
      .DisableEdit {
        display: inline-block;
        width: 280px;
        height: 21px;
        padding: 9px 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
      }
    }
  }
}
.common-screen-container {
  margin-top: 24px;
}
</style>
