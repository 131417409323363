<!-- 工资项 -->
<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="container"
  >
    <div class="title-container">
      <div>工资项</div>
      <div v-if="activeName =='second'">
        <el-button
          @click="
            () => {
              addWagesList();
            }
          "
          type="primary"
          class="common-screen-btn"
          >新增工资项</el-button
        >
      </div>
    </div>
    <!-- 筛选 -->
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>项目类型:</span>
        <el-select
          v-model="params.wageType"
          class="common-screen-input"
          placeholder="请选择"
          @change="commonFun"
        >
          <el-option
            v-for="item in options1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>项目状态:</span>
        <el-select
          v-model="params.calculateState"
          class="common-screen-input"
          placeholder="请选择"
          @change="commonFun"
        >
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-button class="common-screen-btn" @click="() => clear()" plain
        >重 置</el-button
      >
    </div>
    <div class="gap"></div>

    <!-- 所有  我的 -->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="系统自动计算工资项" name="first">
        <!-- 表格 -->
        <el-table
          :data="tableData"
          class="common-table-haveborder common-table-bgGrey th"
          border
        >
          <el-table-column
            label="工资项名称"
            :formatter="tableColumn"
          >
            <template slot-scope="scope">
              <el-button @click="() => {seeDetail(scope.row)}" type="text">{{scope.row.wageName}}</el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="wageTypeName"
            label="工资项类型"
            :formatter="tableColumn"
          ></el-table-column>
          <el-table-column
            prop="remark"
            label="工资项描述"
            :formatter="tableColumn"
          ></el-table-column>
          <el-table-column label="金额/频次" :formatter="tableColumn">
            <template slot-scope="scope">
              <span
                >{{
                  scope.row.calculatePrice ? scope.row.calculatePrice : "--"
                }}/人/{{ scope.row.calculateTypeName }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="adminName"
            label="关联客服"
            :formatter="tableColumn"
          ></el-table-column>
          <el-table-column label="测算状态" :formatter="tableColumn">
            <template slot-scope="scope">
              <!-- <span
                v-show="scope.row.calculateStateName == '系统配置中'"
                class="common-tableStatus-red-bgc"
                >系统配置中</span
              > -->
              <span
                v-show="scope.row.calculateStateName == '进行中'"
                class="common-tableStatus-green-bgc"
                >进行中</span
              >
              <span
                v-show="scope.row.calculateStateName == '已结束'"
                class="common-tableStatus-grey-bgc"
                >已结束</span
              >
            </template>
          </el-table-column>
          <el-table-column label="是否使用" :formatter="tableColumn">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.calculateState"
                :active-value="1"
                :inactive-value="3"
                active-color="#1890FF"
                inactive-color="#DBDBDB"
                @change="(val) => {changeShow(scope.row,val)}"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" :formatter="tableColumn">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="() => systemEdit(scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="text-align: center"
          @size-change="(e) => pageFun(e, 'pageSize')"
          @current-change="(e) => pageFun(e)"
          :page-sizes="pagination.pageSizes"
          :page-size="params.pageSize"
          layout="total, prev, pager, next,sizes, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="手动添加计算工资项" name="second">
        <!-- 表格 -->
        <el-table
          :data="tableData"
          class="common-table-haveborder common-table-bgGrey th"
          border
        >
          <el-table-column
            prop="wageName"
            label="工资项名称"
            :formatter="tableColumn"
          ></el-table-column>
          <el-table-column
            prop="wageTypeName"
            label="工资项类型"
            :formatter="tableColumn"
          ></el-table-column>
          <el-table-column
            prop="remark"
            label="工资项描述"
            :formatter="tableColumn"
          ></el-table-column>
          <el-table-column label="金额/频次" :formatter="tableColumn">
            <template slot-scope="scope">
              <span
                >{{
                  scope.row.calculatePrice ? scope.row.calculatePrice : "--"
                }}/人/{{ scope.row.calculateTypeName }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="adminName"
            label="关联客服"
            :formatter="tableColumn"
          ></el-table-column>
          <el-table-column label="测算状态" :formatter="tableColumn">
            <template slot-scope="scope">
              <span
                v-show="scope.row.calculateStateName == '系统配置中'"
                class="common-tableStatus-red-bgc"
                >系统配置中</span
              >
              <span
                v-show="scope.row.calculateStateName == '进行中'"
                class="common-tableStatus-green-bgc"
                >进行中</span
              >
              <span
                v-show="scope.row.calculateStateName == '已结束'"
                class="common-tableStatus-grey-bgc"
                >已结束</span
              >
            </template>
          </el-table-column>
          <el-table-column label="操作" :formatter="tableColumn">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="() => edit(scope.row)"
                >编辑</el-button
              >
              <el-button
                v-show="scope.row.calculateStateName != '已结束'"
                type="text"
                size="small"
                @click="() => close(scope.row)"
                >关闭</el-button
              >
              <el-button @click="() => del(scope.row)" type="text" size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="text-align: center"
          @size-change="(e) => pageFun(e, 'pageSize')"
          @current-change="(e) => pageFun(e)"
          :page-sizes="pagination.pageSizes"
          :page-size="params.pageSize"
          layout="total, prev, pager, next,sizes, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>

    <!-- 系统-编辑 工资项 -->
    <el-dialog v-dialogDrag title="编辑工资项" :append-to-body="true" :visible.sync="systemDialogVisible" width="30%">
      <el-form ref="sysForm" :model="sysForm" :rules="sysRules" label-width="110px">
        <el-form-item label="所属公司:">
          <span>{{companyName}}</span>
        </el-form-item>
        <el-form-item prop="wageName" label="工资项名称:">
          <span>{{systemRow.wageName}}</span>
        </el-form-item>
        <el-form-item label="工资项属性:">
          <span>{{systemRow.wageAttributeName}}</span>
        </el-form-item>
        <el-form-item prop="wageType" label="工资项类型:">
         <span>{{systemRow.wageTypeName}}</span>
        </el-form-item>
        <el-form-item prop="remark" label="描述:">
          <span>{{systemRow.remark}}</span>
        </el-form-item>
        <el-form-item prop="calculatePrice" label="测算金额:">
          <el-input v-model="sysForm.calculatePrice" type="number" class="common-screen-input_80"></el-input> 元 / 人 / 次
        </el-form-item>
        <el-form-item label="关联客服:" class="re">
          <el-button @click="() => { relationPerson() }" plain icon="el-icon-plus" class="cp">选择人员</el-button>
        </el-form-item>
        <el-form-item>
          <div class="common-chooseItem-container">
            <div
              v-for="(item, index) in checkedList"
              :key="index"
              class="common-choose-item pl"
            >
              <span>{{ item.name }}</span
              >
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="systemDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sysSendForm()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 新增&&编辑  工资项 -->
    <el-dialog
      v-dialogDrag
      :title="aeType=='add'? '新增工资项': '编辑工资项'"
      :visible.sync="dialogVisible"
      width="30%"
      append-to-body
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="110px">
        <el-form-item label="所属公司:">
          <span>{{companyName}}</span>
        </el-form-item>
        <el-form-item prop="wageName" label="工资项名称:">
          <el-input v-model="form.wageName" class="common-screen-input_100"></el-input>
        </el-form-item>
        <el-form-item label="工资项属性:">
          <span>手动添加计算</span>
        </el-form-item>
        <el-form-item prop="wageType" label="工资项类型:">
          <el-select v-model="form.wageType" class="common-screen-input_100" placeholder="请选择">
            <el-option v-for="item in wagesOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="remark" label="描述:">
          <el-input
            class="common-screen-input_100"
            type="textarea"
            :rows="4"
            placeholder="请输入内容"
            v-model="form.remark">
          </el-input>
        </el-form-item>
        <el-form-item prop="calculateDate" label="测算月份:">
          <el-date-picker
            class="common-screen-input_100"
            v-model="form.calculateDate"
            type="month"
            value-format="yyyy-MM"
            placeholder="选择月">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="calculatePrice" label="测算金额:">
          <el-input v-model="form.calculatePrice" type="number" class="common-screen-input_80"></el-input> 元 / 人 / 次
        </el-form-item>
        <el-form-item label="关联客服:" class="re">
          <el-button @click="() => { relationPerson() }" plain icon="el-icon-plus" class="cp">选择人员</el-button>
        </el-form-item>
        <el-form-item>
          <div class="common-chooseItem-container">
            <div
              v-for="(item, index) in checkedList"
              :key="index"
              class="common-choose-item pl"
            >
              <span>{{ item.name }}</span
              >
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="aeType == 'add'" type="primary" @click="sendForm()"
          >确 定</el-button
        >
        <el-button v-if="aeType == 'edit'" type="primary" @click="sendFormUpdate()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 关闭，删除 弹窗 -->
    <el-dialog v-dialogDrag :append-to-body="true" :visible.sync="cdVisible" width="520px">
      <template slot="title"><div style="display:flex;align-items: center;"><img src="~@/assets/images/if-exclamation@1x.png" class="imgs"><span v-if="type == 'close'" class="diatit">关闭工资项</span><span v-if="type == 'del'" class="diatit">删除工资项</span> </div></template>
      <div v-if="type == 'close'" class="diacon">确定关闭此工资项吗？该工资项关闭后将无法算入工资</div>
      <div v-if="type == 'del'" class="diacon">确定删除此工资项吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="cdVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" type="primary" @click="() => {request()}">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 选择人员 弹 -->
    <el-dialog
      v-dialogDrag
      title="选择人员"
      :append-to-body="true"
      :visible.sync="personVisible"
      width="630px"
      :before-close="
        () => {
          personVisible = false;
          personData = []
        }
      "
      :close-on-click-modal="false"
    >
      <div class="common-chooseItem-container">
        <div
          v-for="(item, index) in checkedList"
          :key="index"
          class="common-choose-item"
        >
          <span>{{ item.name }}</span
          ><i
            @click="
              () => {
                deletePerson(item);
              }
            "
            class="el-icon-circle-close"
          ></i>
        </div>
      </div>
      <br /><br />
      <div class="common-person-container">
        <div class="person-title">
          <div class="left-text">组织成员</div>
          <div class="right-seach">
            <el-input
              class="seach-input"
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              @change="(val) => seachFun(val)"
              v-model="seachInfo"
            ></el-input>
          </div>
        </div>
        <div class="person-content">
          <div class="content-left">
            <el-tree
              :data="selectDepart"
              :props="defaultProps"
              :check-strictly="true"
              @check-change="handleCheckChange"
              show-checkbox
            ></el-tree>
          </div>
          <div class="content-right">
              <div v-for="(item, index) in personData" :key="index">
                <el-checkbox
                  v-model="item.checked"
                  @change="
                    (val) => {
                      changePerson(val, item);
                    }"
                  >{{ item.nickName }}</el-checkbox
                >
              </div>
            </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="common-screen-btn"
          @click="
            () => {
              personVisible = false;
              personData = []
            }
          "
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              noticeFun();
            }"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <wages-details ref="details" :rowName="rowName" :drawer="drawer" @sysHandleClose="sysHandleClose"></wages-details>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { wagesList, wagesAdd, updateWages,selectById } from "../../service/portrait.js";
import { Config, tableColumn } from "@/utils";
import { commonDepartAll, personList } from "../../service/common.js";
import { getUserList } from "../../service/manage.js";
import wagesDetails from "./children/wagesDetails.vue"

export default {
  name: "wages",
  components: { wagesDetails },
  props: {},
  data() {
    // var validateChecked = (rule, value, callback) => {
    //   console.log(rule,value,'----------+6666666');
    //     if (!(this.checkedList&&this.checkedList.length)) {
    //       callback(new Error('请选择'));
    //     } else {
    //       // if (this.checkedList&&this.checkedList.length) {
    //       //   this.$refs.form.validateField('checked');
    //       // }
    //       callback();
    //     }
    //   };
    return {
      tableColumn,
      params: {
        wageAttribute: 1,
        pageNum: 1,
        pageSize: 10
      },
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      options1: [
        { value: "1", label: "扣款" },
        { value: "2", label: "奖励" },
        { value: "3", label: "补贴" }
      ],
      options2: [
        { value: "1", label: "进行中" },
        // { value: "2", label: "系统配置中" },
        { value: "3", label: "已结束" }
      ],
      companyId: this.$sto.get(Config.constants.userInfo).company_id, // 所属公司
      companyName: this.$sto.get(Config.constants.userInfo).company_name, // 所属公司
      loading: false,
      tableData: [],
      dialogVisible: false,
      activeName: "first",
      form: {
        wageName: "",
        wageAttribute: "2",
        wageType: "",
        remark: "",
        calculateDate: "",
        calculatePrice: "",
        calculateType: "1",
        adminId: "",
        departmentId: ""
      },
      rules: {
         wageName: [
           { required: true, message: '请输入', trigger: 'change' },
         ],
         wageType: [
           { required: true, message: '请选择', trigger: 'change' },
         ],
         remark: [
           { required: true, message: '请输入', trigger: 'change' },
         ],
         checked: [
           { required: true, message: '请选择', trigger: 'change' },
          //  { validator: validateChecked, message: '请选择', trigger: 'change' }
         ],
         calculatePrice: [
           { required: true, message: '请输入', trigger: 'change' },
         ],
      },
      wagesOptions: [
        {
          label: '扣款',
          value: '1'
        },
        {
          label: '奖励',
          value: '2'
        },
        {
          label: '补贴',
          value: '3'
        },
      ],
      rowName: "",
      drawer: false,
      systemDialogVisible: false, //系统--编辑工资项弹
      sysForm: {
        calculatePrice: "",
      },
      sysRules: {
        calculatePrice: [
           { required: true, message: '请输入', trigger: 'change' },
         ],
      },
      personVisible: false,
      checkedList: [],
      personData: [],//部门里人员
      seachInfo: '',
      roleData: [],
      defaultProps: {
        children: 'child',
        label: 'name'
      },
      selectDepart: [],//部门
      departParams: {
        pageNum: 1,
        pageSize: 99999
      },
      type: "",//关闭&&删除
      aeType: "",//新增&&编辑
      cdVisible: false, //关闭&&删除
      systemRow: {},
      delParams: {}
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.companyId) {
      this.params.companyId = this.companyId;
    }
    this.getTableData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    async getTableData() {
      this.loading = true;
      let params = { ...this.params };
      const { data } = await wagesList(params);
      this.tableData = data.list;
      this.pagination.total = data.total;
      this.pagination.pageSize = data.size;
      this.loading = false;
    },
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.getTableData();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.getTableData();
    },
    clear() {
      // 重置
      if(this.activeName == 'second'){
        this.params = {
          wageAttribute: 2,
          companyId: this.companyId,
          pageNum: 1,
          pageSize: 10
        };
      }else {
        this.params = {
          wageAttribute: 1,
          companyId: this.companyId,
          pageNum: 1,
          pageSize: 10
        };
      }
      
      this.getTableData();
    },
    addWagesList() {
      this.aeType = 'add'
      this.dialogVisible = true;
    },
    // 系统的编辑
    async systemEdit(row){
      this.systemRow = row
      const { data } = await selectById({id: row.id})
      let userDepartId = Number(data.departmentId)
      if(userDepartId){
        this.departParams.userDepartId = userDepartId
        let params = {
          pageNum: 1,
          pageSize: 99999,
          companyId: this.companyId,
          userDepartId: userDepartId
        }
        let resData = (await getUserList(params)).data;
        this.checkedList = []
        let adminList = []
        if(data.adminId){
          adminList = data.adminId.split(',')
        }
        adminList.forEach((item) => {
          resData.records.forEach((item2) => {
            if(item == item2.id){
              this.checkedList.push({id: item2.id, name: item2.nickName})
            }
          })
        })
      }
      this.systemDialogVisible = true
      
      this.sysForm.calculatePrice = row.calculatePrice
    },
    // 系统修改
    async sysSendForm(){
      let params = {
        id: this.systemRow.id,
        companyId: this.companyId,
        wageName: this.systemRow.wageName,
        wageAttribute: this.systemRow.wageAttribute,
        wageType: this.systemRow.wageType,
        remark: this.systemRow.remark,
        calculateDate: this.systemRow.calculateDate,
        calculatePrice: this.sysForm.calculatePrice,
        calculateType: this.systemRow.calculateType,
        // adminId: this.systemRow.adminId,
        departmentId: this.systemRow.departmentId,
        calculateState: this.systemRow.calculateState,
      }
      let adminList = []
      if(this.checkedList&&this.checkedList.length){
        this.checkedList.forEach((item) => {
          adminList.push(item.id)
        })
      }
      params.adminId = adminList.join(',')
      if(!params.adminId){
        return this.$message.error('请选择关联人员')
      }
      params.departmentId = this.departParams.userDepartId
      if(!params.departmentId){
        delete params.departmentId
      }
      const res = await updateWages(params);
      if(res.code == 200) {
        this.$message.success("编辑成功")
      }
      this.systemDialogVisible = false
      this.getTableData()
    },
    seeDetail(row){
      this.rowName = row.wageName
      this.drawer = true
      this.$refs.details.getDetailsData(row.wageName)
    },
    // 手动的
    async edit(row) {
      this.aeType = 'edit'
      const { data } = await selectById({id: row.id})
      this.form = {
        id: data.id,
        wageName: data.wageName,
        wageAttribute: data.wageAttribute,
        wageType: data.wageType + '',
        remark: data.remark,
        calculateDate: data.calculateDate,
        calculatePrice: data.calculatePrice,
        calculateType: data.calculateType,
        departmentId: data.departmentId,
        calculateState: data.calculateState,
      }
      // 处理部门---人员
      let userDepartId = Number(data.departmentId)
      if(userDepartId){
        this.departParams.userDepartId = userDepartId
        let params = {
          pageNum: 1,
          pageSize: 99999,
          companyId: this.companyId,
          userDepartId: userDepartId
        }
        let resData = (await getUserList(params)).data;
        this.checkedList = []
        let adminList = []
        if(data.adminId){
          adminList = data.adminId.split(',')
        }
        adminList.forEach((item) => {
          resData.records.forEach((item2) => {
            if(item == item2.id){
              this.checkedList.push({id: item2.id, name: item2.nickName})
            }
          })
        })
      }
      this.dialogVisible = true;
      this.getTableData()
      
      console.log(row, "edit");
    },
    close(row) {
      this.type = 'close'
      this.cdVisible = true
      this.delParams = {
        companyId: this.companyId,
        id: row.id,
        wageName: row.wageName,
        wageAttribute: row.wageAttribute,
        wageType: row.wageType + '',
        remark: row.remark,
        calculateDate: row.calculateDate,
        calculatePrice: row.calculatePrice,
        calculateType: row.calculateType,
        departmentId: row.departmentId,
        calculateState: row.calculateState,
        adminId: row.adminId,
      }
      console.log(row, "close");
    },
    async del(row) {
      this.type = 'del'
      this.delParams = {
        companyId: this.companyId,
        id: row.id,
        wageName: row.wageName,
        wageAttribute: row.wageAttribute,
        wageType: row.wageType + '',
        remark: row.remark,
        calculateDate: row.calculateDate,
        calculatePrice: row.calculatePrice,
        calculateType: row.calculateType,
        departmentId: row.departmentId,
        calculateState: row.calculateState,
        adminId: row.adminId,
      }
      
      this.cdVisible = true
      console.log(row, "del");
    },
    async request(){
      if(this.type == 'del'){
        let params = {...this.delParams}
        params.deleteFlag = "1"
        const res = await updateWages(params);
        if(res.code == 200) {
          this.$message.success("删除成功")
        }
      }else if(this.type =='close'){
        let params = {...this.delParams}
        params.calculateState = "3"
        const res = await updateWages(params);
        if(res.code == 200) {
          this.$message.success("关闭成功")
        }
      }
      
      this.cdVisible = false
      this.delParams = {}
      this.getTableData()
    },
    handleClose() {
      this.dialogVisible = false
      this.$refs.form.resetFields()
      this.checkedList = []
    },
    handleClick(tab) {
      if(tab.name == 'second'){
        this.params.wageAttribute = 2
        this.getTableData()
      }else{
        this.params.wageAttribute = 1
        this.getTableData()
      }
    },
    async commonDepartAll(id) {
      // 部门 下拉
      let resData = (await commonDepartAll({}, id)).data;
      this.selectDepart = resData ? resData : [];
    },
    // 选择人员按钮
    relationPerson(){
      this.personVisible = true
      this.commonDepartAll(this.companyId);
      if(this.departParams.userDepartId){
        this.getPersonData()
      }
    },
    async getPersonData() {
      // 根据部门id获取人员
      let params = { ...this.departParams };
      params.companyId = this.companyId
      // params.userDepartId = 
      if (typeof params.userDepartId == "object") {
        delete params.userDepartId;
      }
      let resData = (await getUserList(params)).data;
      this.personData = resData.records;
      if (resData.records && resData.records.length) {
          resData.records.map((item) => {
              item.checked = false
              this.checkedList.map((itemTwo) => {
                  if (item.id == itemTwo.id) {
                      item.checked = true
                  }
              })
          })
      }
    },
    // 点击部门
    handleCheckChange(data, checked, indeterminate) {
        console.log(data, checked, indeterminate,data.id,data.$treeNodeId,'-------+++');
        if(checked){
          this.departParams.userDepartId = data.id
        }else {
          this.departParams.userDepartId = ""
        }
        this.getPersonData()
    },
    deletePerson (row) { // 删除选中人员
        let checkedList = [...this.checkedList]
        let personData = [...this.personData]
        checkedList = checkedList.filter((item) => {
            return row.id != item.id
        })
        personData.map((item) => {
            if (row.id == item.id) {
                item.checked = false
            }
        })
        this.checkedList = checkedList
        this.personData = personData
    },
    seachFun (val) { // 搜索人员
      let params = {
          companyId: this.companyId,
          name: val,
          pageNum: 1,
          pageSize: 999999
      }
      this.personList(params)
    },
    async personList(params) { // 获取人员
      let { data } = await personList(params)
      let checkedList = [...this.checkedList]
      if (data.records && data.records.length) {
          data.records.map((item) => {
              item.checked = false
              checkedList.map((itemTwo) => {
                  if (item.id == itemTwo.id) {
                      item.checked = true
                  }
              })
          })
      }
      this.personData = data.records || []
    },
    changePerson(val, row) { // 单选人员
            let checkedList = [...this.checkedList]
            if (val) {
              checkedList.push({id: row.id, name: row.nickName})
            } else {
                checkedList = checkedList.filter((item) => {
                    return row.id != item.id
                })
            }
            this.checkedList = checkedList
    },
    noticeFun() { //选择人员确认按钮
        this.personVisible = false
    },
    // 新增
     sendForm(){
      this.$refs['form'].validate(async(valid) => {
          if (valid) {
            let params = {...this.form}
            params.companyId = this.companyId
            params.wageAttribute = "2"
            let adminList = []
            if(this.checkedList&&this.checkedList.length){
              this.checkedList.forEach((item) => {
                adminList.push(item.id)
              })
            }
            params.adminId = adminList.join(',')
            params.departmentId = this.departParams.userDepartId
            if(!params.adminId){
              return this.$message.error('请选择关联人员')
            }
            const res = await wagesAdd(params);
            if(res.code == 200) {
              this.$message.success("新增成功")
            }
            this.$refs.form.resetFields()
            this.checkedList = []
            this.dialogVisible = false
            this.getTableData()
          } else {
            return false;
          }
      })
    },
    // switch改变
    async changeShow(row,val){
      let params = {
        id: row.id,
        companyId: this.companyId,
        wageName: row.wageName,
        wageAttribute: row.wageAttribute,
        wageType: row.wageType,
        remark: row.remark,
        calculateDate: row.calculateDate,
        calculatePrice: row.calculatePrice,
        calculateType: row.calculateType,
        adminId: row.adminId,
        departmentId: row.departmentId,
        calculateState: val,
      }
      if(!params.departmentId){
        delete params.departmentId
      }
      const res = await updateWages(params);
      console.log(res, "*****");
      if(res.code == 200) {
        this.$message.success("编辑成功")
      }
      this.getTableData()
    },
    // 编辑
    async sendFormUpdate(){
      this.$refs['form'].validate(async(valid) => {
        if (valid) {
          let params = {...this.form}
          let adminList = []
          if(this.checkedList&&this.checkedList.length){
            this.checkedList.forEach((item) => {
              adminList.push(item.id)
            })
          }
          params.adminId = adminList.join(',')
          if(!params.adminId){
            return this.$message.error('请选择关联人员')
          }
          params.departmentId = this.departParams.userDepartId
          if(!params.departmentId){
            delete params.departmentId
          }
          const res = await updateWages(params);
          if(res.code == 200) {
            this.$message.success("编辑成功")
          }
          this.$refs.form.resetFields()
          this.checkedList = []
          this.dialogVisible = false
          this.getTableData()
        }else {
            return false;
        }
      })
    },
    sysHandleClose(){
      this.drawer = false
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  text-align: left;
  /deep/.el-popover__reference-wrapper {
    margin-left: 10px;
  }
  .gap {
    position: absolute;
    left: 0;
    right: 0;
    height: 16px;
    background-color: #ededed;
  }
  .common-screen-container {
    margin-top: 16px;
    margin-left: 32px;
  }
  /deep/.el-tabs__content{
    padding: 0 32px;
  }
  .el-tabs {
    margin-top: 32px;
  }
  
}
.cp {
    padding: 8px 12px;
    border-color: #1890ff;
    color: #1890ff;
  }
/////新弹窗样式start
  .imgs{
  width: 24px;
  height: 24px;
  background-color: #ffdbdc;
  border-radius: 50%;
  vertical-align: middle;
}
.diatit{
  margin-left: 12px;
  font-size: 20px;
  color: #333;
}
.diacon{
  font-size: 14px;
  color: #101010;
  margin-left: 10px;
}
/////新弹窗样式end
.pl{
  line-height: 1.5;
  padding: 4px 12px;
}
.re {
  position: relative;
  &::before{
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
    position: absolute;
    top: 10px;
    left: 28px;
  }
}
</style>
